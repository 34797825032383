<template>
  <div class="web">
    <!-- <iframe src="https://cdn.mekoomall.com/static/pc/manager/消费者评价规则.txt" frameborder="0" scrolling="no" width="1200" height="500"></iframe> -->
    <a href="https://cdn.mekoomall.com/static/pc/manager/消费者评价规则.txt"></a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.web {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin: 16px auto;
  }
  pre {
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap;
    p {
      text-align: right;
    }
  }
}
</style>